<template>
  <div class="modal-card">
    <section class="modal-card-body" :class="{'loaded': ! isLoading}">
      <hero-bar v-if="data" class="mb-10">
        #{{ data.ids }} {{ $t('shuttleRegisterDetails') }}

        <small v-if="data.trashed" slot="right" class="has-text-grey">{{ $t('canceled') }}</small>
        <b-dropdown v-else slot="right" hoverable aria-role="list">
          <button slot="trigger" class="button is-danger">
            <span>{{ $t('cancelRequest') }}</span>
            <b-icon icon="menu-down" />
          </button>

          <b-dropdown-item aria-role="listitem" @click="$parent.$parent.cancelRequest(id, false)">Sadece Bu Günü</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="$parent.$parent.cancelRequest(id, true)">Tüm Günleri</b-dropdown-item>
        </b-dropdown>
      </hero-bar>
      <div v-if="data">
        <card-component class="mb-10">
          <div class="columns no-wrap">
            <div class="column">
              <b-field label="Güzergah:" horizontal>
                {{ data.plan.name }}
              </b-field>

              <b-field label="Tipi:" horizontal>
                <span class="tag" :data-label="data.plan.type">{{ $t(data.plan.type) }}</span>
              </b-field>

              <b-field label="Saat:" horizontal>
                <span class="tag">{{ data.plan.time }}</span>
              </b-field>

              <b-field label="Tarih:" horizontal>
                <span class="tag">{{ data.register_date | formatDateYear }}</span>
              </b-field>

              <b-field label="Adres:" horizontal>
                {{ data.address }}
              </b-field>

              <b-field label="Açıklama:" horizontal>
                {{ data.comment }}
              </b-field>
            </div>

            <div class="column">
              <b-field label="Araç:" horizontal>
                <span v-if="data.car" class="tag">{{ data.car.plate }}</span>
                <span v-else>{{ $t('unassigned') }}</span>
              </b-field>

              <b-field label="Sürücü:" horizontal>
                <span v-if="data.driver">{{ data.driver.name }}</span>
                <span v-else>{{ $t('unassigned') }}</span>
              </b-field>

              <b-field label="Sürücü Telefon:" horizontal>
                <span v-if="data.driver">{{ data.driver.phone | phone }}</span>
                <span v-else>...</span>
              </b-field>

              <b-field label="BŞ. Açıklama:" horizontal>
                <b-input
                  v-if="checkPermission('shuttle_set_driver')"
                  v-model="adminNote"
                  type="textarea"
                  maxlength="200"
                  custom-class="is-static is-fullwidth"
                />
                <span v-else>{{ data.admin_note }}</span>
              </b-field>
            </div>
          </div>
        </card-component>

        <card-component v-if="hasRole('Super Admin', 'Lead Driver')" :title="$t('reviews')">
          <b-table v-if="data.reviews.length" :data="data.reviews" class="no-wrap">
            <b-table-column v-slot="props" :label="$t('author')" field="author">
              {{ props.row.author.name }}
              <b-tag class="is-size-7" rounded>
                {{ props.row.author.id === data.driver_id && props.row.author.role === 'Driver' ? 'Sürücü' : 'Yolcu' }}
              </b-tag>
            </b-table-column>
            <b-table-column v-slot="props" :label="$t('rating')" field="rating">
              <b-icon v-for="i in props.row.rating" :key="'star' + i" icon="star" size="is-small" type="is-primary" />
              <b-icon v-for="i in (5 - props.row.rating)" :key="'star-outline' + i" icon="star-outline" size="is-small" type="is-primary" />
            </b-table-column>
            <b-table-column v-slot="props" :label="$t('description')" field="body">
              {{ props.row.body }}
            </b-table-column>
            <b-table-column v-slot="props" :label="$t('date')" field="created_at">
              {{ props.row.created_at | formatDateTimeYear }}
            </b-table-column>
          </b-table>
          <template v-else>
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="table" size="is-large" />
              </p>
              <p>{{ $t('nodata') }}</p>
            </div>
          </template>
        </card-component>

        <b-message v-if="data.plan.description" type="mt-3 mb-3 is-info">
          {{ data.plan.description }}
        </b-message>

        <card-component>
          <div class="level-item has-text-centered">
            <gmap-image
              :markers="[data.plan.start_location, data.location]"
              :size="[1100, 300]"
              :zoom="10"
            />
          </div>
        </card-component>
      </div>
    </section>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  name: 'ShuttleRegisterDetails',
  components: {
    HeroBar: () => import('./HeroBar'),
    CardComponent: () => import('./CardComponent'),
    GmapImage: () => import('./GMapImage'),
  },
  props: {
    id: {
      type: Number,
      default: null
    },
  },
  data () {
    return {
      data: null,
      isLoading: true,
      adminNote: null,
    }
  },
  watch: {
    adminNote: debounce(function (n, o) {
      if (o != null) {
        this.updateRegister();
      }
    }, 1300),
  },
  mounted () {
    this.getDetails()
  },
  methods: {
    getDetails () {
      this.showSpinner()
      this.$http.get(`shuttle-register-details/${this.id}?with_trashed=1`)
        .then(({ data }) => {
          this.data = data;
          this.adminNote = data.admin_note;
          this.isLoading = false;
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t('error'),
            type: 'is-warning',
            duration: 5000
          });
          this.$parent.close();
        })
        .finally(() => {
          this.hideSpinner()
        });
    },
    updateRegister () {
      this.$http.put('shuttle-register-update', {
        ids: [this.id],
        admin_note: this.adminNote
      }).then(({ data }) => {
        if (data.success) {
          this.$buefy.toast.open({
            message: 'Not Başarıyla Güncellendi',
            type: 'is-success',
            duration: 2000
          });
        }
      })
      .catch(() => {
          this.$buefy.toast.open({
            message: this.$t('error'),
            type: 'is-warning',
            duration: 5000
          });
        })
    },
  }
}
</script>

<style scoped>
  .modal-card {
    width: 1100px;
  }
  .modal-card-body {
    background-color: #f8f8f8;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.26s ease;
  }
  .column {
    max-width: 49%;
  }
</style>
